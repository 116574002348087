import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {
  addMinutes,
  addMonths,
  compareAsc,
  format,
  lastDayOfMonth,
  startOfMonth
} from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useHistory, useLocation } from "react-router-dom";

import {
  AppBar,
  Box,
  Button,
  DialogContentText,
  FormControl,
  InputLabel,
  LinearProgress,
  Link,
  makeStyles,
  Paper,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from "@material-ui/core";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import LoadingButton from "../../components/LoadingButton/LoadingButton";
import MonthlyPaymentsList from "../../components/MonthlyPaymentsList";
import { MonthStatusEnum } from "../../generated/globalTypes";
import { MonthlyPaymentStatus } from "../../generated/globalTypes";
import { LatestPaymentsStatus } from "../../generated/LatestPaymentsStatus";
import { useAuth0 } from "../../utils/auth0Provider";

import MonthlyPaymentSettingsForm from "../../components/MonthlyPaymentSettingsForm";
import { Brands as IBrands } from "../../generated/Brands";
import { LockMonthlyPayments_lockMonthlyPayments_error } from "../../generated/LockMonthlyPayments";
import {
  monthlyPaymentSummaries,
  monthlyPaymentSummaries_monthlyPaymentSettings_monthlyPaymentSetting as ISettings,
  monthlyPaymentSummaries_monthlyPaymentSummaries,
  monthlyPaymentSummariesVariables
} from "../../generated/monthlyPaymentSummaries";
import CloseMonthMutation from "../../mutations/CloseMonthMutation";
import LockMonthMutation from "../../mutations/LockMonthMutation";
import BrandsQuery from "../../queries/BrandsQuery";
import LatestPaymentsStatusQuery from "../../queries/LatestPaymentsStatusQuery";
import MonthlyPaymentsQuery from "../../queries/MonthlyPaymentSummariesQuery";
import { DateTransformer } from "./DateTransformer";
import { updateGlobal } from "./GlobalStateHelper";

interface ITabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: ITabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
const currentYear = new Date().getUTCFullYear();
const currentMonth = new Date().getUTCMonth();
const monthsCount = 24;
const dateTransformer = new DateTransformer(
  monthsCount,
  currentMonth,
  currentYear
);

export interface IGlobalStateElement {
  [key: string]: string | boolean | undefined;
  status?: string;
  isExportDone?: boolean;
  isCacheDirty?: boolean;
}
const MonthlyPaymentsListContainer = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const manageMonthlyPayments = hasPermission("manage:monthly_payments");

  const after = queryParams.get("after") || undefined;
  const before = queryParams.get("before") || undefined;
  const [monthState, setMonth] = useState<string>(
    dateTransformer.getDate(dateTransformer.getDateOptions()[0])
  );
  const [
    statusError,
    setStatusError
  ] = useState<LockMonthlyPayments_lockMonthlyPayments_error | null>(null);
  const [lockMonthPushed, setLockMonth] = useState<boolean>(false);
  const [closeMonthPushed, setCloseMonth] = useState<boolean>(false);
  const [settingsPushed, setMonthSettings] = useState<boolean>(false);
  const [tabValue, setTab] = useState<number>(1);
  const [dialogContent, setDialogContent] = useState<string>("");
  const [globalState, setGlobalState] = useState<
    Map<string, IGlobalStateElement>
  >(new Map());
  const [statusFilter, setStatusFilter] = useState<MonthlyPaymentStatus | "">(
    ""
  );

  const paymentsParams: monthlyPaymentSummariesVariables = {
    after,
    before,
    isExport: false,
    limit: 50,
    month: monthState,
    paymentStatus: statusFilter ? statusFilter : undefined
  };
  const { loading, error, data, refetch } = useQuery<monthlyPaymentSummaries>(
    MonthlyPaymentsQuery,
    {
      errorPolicy: "all",
      variables: paymentsParams
    }
  );
  const { data: brandsData } = useQuery<IBrands>(BrandsQuery, {
    variables: {
      ids: data?.monthlyPaymentSettings?.monthlyPaymentSetting?.brandBundle
    }
  });

  const [csvExport, { loading: lazyLoading, data: exportData }] = useLazyQuery<
    monthlyPaymentSummaries
  >(MonthlyPaymentsQuery, {
    fetchPolicy: "network-only",
    variables: {
      isExport: true,
      month: monthState
    }
  });

  const { loading: statusLoading } = useQuery<LatestPaymentsStatus>(
    LatestPaymentsStatusQuery,
    {
      fetchPolicy: "network-only",
      onCompleted: result => {
        setGlobalState(
          updateGlobal(globalState, monthState, {
            status: result.getLatestPaymentsStatus?.status
          })
        );
      },
      variables: {
        month: monthState
      }
    }
  );

  const [lockMonth] = useMutation(LockMonthMutation, {
    onCompleted: result => {
      if (!result.lockMonthlyPayments.error) {
        setGlobalState(
          updateGlobal(globalState, monthState, {
            status: result.lockMonthlyPayments?.data?.status
          })
        );
      } else {
        setStatusError(result.lockMonthlyPayments.error);
      }
    },
    variables: {
      month: monthState
    }
  });
  const [closeMonth] = useMutation(CloseMonthMutation, {
    onCompleted: result => {
      if (!result.closeMonthlyPayments.error) {
        const nextMonth = format(
          addMonths(new Date(monthState), 1),
          "yyyy-MM-dd"
        );
        const newGlobalState = updateGlobal(globalState, nextMonth, {
          isCacheDirty: true
        });
        setGlobalState(
          updateGlobal(newGlobalState, monthState, {
            status: result.closeMonthlyPayments?.data?.status
          })
        );
      } else {
        setStatusError(result.closeMonthlyPayments.error);
      }
    },
    variables: {
      month: monthState
    }
  });
  useEffect(() => {
    const current = globalState.get(monthState);
    if (current?.isCacheDirty === true) {
      setGlobalState(
        updateGlobal(globalState, monthState, { isCacheDirty: false })
      );
      refetch();
    }
  }, [monthState, globalState, refetch]);
  useEffect(() => {
    const exportState = globalState.get(monthState)?.isExportDone;
    if (!exportData || !exportState) {
      return;
    }
    setGlobalState(
      updateGlobal(globalState, monthState, { isExportDone: false })
    );
    setTimeout(() => {
      csvLink.current.link.click();
    });
  }, [exportData, monthState, globalState]);

  const firstDateOfCurrentMonth = startOfMonth(new Date());
  const currentState = globalState.get(monthState);
  const canCloseOrLockMonth =
    !statusLoading &&
    compareAsc(new Date(monthState), firstDateOfCurrentMonth) === -1;

  const monthlyPaymentsData = (() => {
    if (data && data.monthlyPaymentSummaries) {
      return data.monthlyPaymentSummaries;
    } else {
      const defaultData: monthlyPaymentSummaries_monthlyPaymentSummaries = {
        __typename: "MonthlyPaymentSummariesConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        }
      };
      return defaultData;
    }
  })();

  const monthlyPaymentSettingsData = (() => {
    if (
      data &&
      data.monthlyPaymentSettings &&
      data.monthlyPaymentSettings.monthlyPaymentSetting
    ) {
      return data.monthlyPaymentSettings.monthlyPaymentSetting;
    } else {
      const defaultData: ISettings = {
        __typename: "MonthlyPaymentSettings",
        brandBundle: [],
        month: monthState,
        ringfenceCommissionType: false
      };
      return defaultData;
    }
  })();

  const handleCloseSettings = () => {
    setMonthSettings(false);
  };

  const onNextPage = () => {
    const newAfter = monthlyPaymentsData.pageInfo.endCursor || undefined;

    if (newAfter) {
      history.push({
        pathname: history.location.pathname,
        search: `?after=${newAfter}`
      });
    }
  };

  const onPreviousPage = () => {
    const newBefore = monthlyPaymentsData.pageInfo.startCursor || undefined;

    if (newBefore) {
      history.push({
        pathname: history.location.pathname,
        search: `?before=${newBefore}`
      });
    }
  };
  const getLastUpdateDate = (
    paymentsData: monthlyPaymentSummaries
  ): string | undefined => {
    return paymentsData?.monthlyPaymentSummaries?.edges
      .reduce(
        (prev, cur) => {
          if (prev.node.updatedAt.valueOf() < cur.node.updatedAt.valueOf()) {
            return cur;
          }
          return prev;
        },
        { node: { updatedAt: "" } }
      )
      .node.updatedAt.split(".")[0]
      .replace("T", " ");
  };

  const getLocalDate = () => {
    return addMinutes(new Date(monthState), new Date().getTimezoneOffset());
  };
  const csvLink: any = useRef(null);

  const handleStateChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: MonthlyPaymentStatus | ""
  ) => {
    setStatusFilter(newStatus);
    paymentsParams.paymentStatus = newStatus ? newStatus : undefined;
    refetch(paymentsParams);
  };

  const handleCurrentStatus = (status: string | undefined) => {
    let callToAction;
    let statusText;
    let button;

    switch (status) {
      case MonthStatusEnum.LOCKED:
        callToAction = "Close Month";
        statusText = "Locked";
        button = (
          <Button
            color="primary"
            variant={"contained"}
            onClick={() => {
              setCloseMonth(true);
              const content = "Do you really want close month?";
              setDialogContent(content);
            }}
          >
            {callToAction}
          </Button>
        );
        break;
      case MonthStatusEnum.CLOSED:
        callToAction = "Close Month";
        statusText = "Closed";
        button = null;
        break;
      default:
        callToAction = "Begin Closing Month";
        statusText = "Open";
        button = (
          <Button
            color="primary"
            variant={"contained"}
            onClick={() => {
              setLockMonth(true);
            }}
          >
            {callToAction}
          </Button>
        );
    }

    return (
      <>
        <Box
          padding={2}
          bgcolor="#d3d3d3"
          border={1}
          borderColor={"#000000"}
          fontSize={18}
          width={450}
          height={60}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          Status: {statusText}
          {manageMonthlyPayments && canCloseOrLockMonth && button}
        </Box>
      </>
    );
  };
  return (
    <Paper className={`${classes.paper}`}>
      {(loading || lazyLoading) && <LinearProgress />}
      {error && !monthlyPaymentsData && <div className={classes.errorBar} />}
      <Typography variant="h5">Monthly Payments</Typography>
      <div className={`${classes.header}`}>
        <FormControl disabled={loading}>
          <InputLabel>Month</InputLabel>
          <Select
            native
            onChange={event => {
              setMonth(event.target.value as string);
            }}
            required
            value={monthState}
          >
            {dateTransformer.getDateOptions().map(option => (
              <option key={option} value={dateTransformer.getDate(option)}>
                {option}
              </option>
            ))}
          </Select>
        </FormControl>
        <div className={`${classes.left}`} />
        <div> Last data update: {getLastUpdateDate(data!)}</div>
      </div>
      <AppBar position="static">
        <Tabs
          value={tabValue}
          onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
            setTab(newValue);
          }}
        >
          <Tab value={1} label="All Payments" wrapped />
          {/*<Tab value={2} disabled label="Approved" />*/}
          {/*<Tab value={3} disabled label="Rollover" />*/}
          {/*<Tab value={4} disabled label="Detailed Report" />*/}
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={1}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {handleCurrentStatus(currentState?.status)}
          <Box hidden={!manageMonthlyPayments}>
            <Button
              variant="contained"
              onClick={() => {
                setMonthSettings(true);
              }}
            >
              Monthly Settings
            </Button>
          </Box>
        </Box>
        {lockMonthPushed && (
          <ConfirmationAlert
            open={true}
            title={"Begin Closing Month End"}
            content={`Clicking “OK” will lock the conversions (clicks, events) and transactional data for the given month so that admin users can begin closing the month. “Adjustments” may still be added after this point\n
            `}
            onPositive={async () => {
              const res = await lockMonth();
              const status = res.data.lockMonthlyPayments.data?.status;

              if (status) {
                setGlobalState(
                  updateGlobal(globalState, monthState, { status })
                );
              }
              setLockMonth(false);
            }}
            onNegative={() => {
              setLockMonth(false);
            }}
            positiveAction={"OK"}
          >
            <DialogContentText className={classes.whiteSpace}>
              <span>
                {`Month Start: ${startOfMonth(getLocalDate())}`}
                <br />
                {`Month End: ${lastDayOfMonth(getLocalDate())}`}
                <br />
                <br />
                Monthly Settings
                <Tooltip
                  interactive
                  title={
                    <p>
                      Monthly Payments Settings can be modified by,{" "}
                      <Link
                        underline={"always"}
                        href="#"
                        onClick={() => {
                          setCloseMonth(false);
                          setMonthSettings(true);
                        }}
                      >
                        opening up monthly settings
                      </Link>{" "}
                      , and re-running the month’s payments report.{" "}
                    </p>
                  }
                  placement="top"
                  leaveDelay={3000}
                >
                  <HelpOutlineIcon fontSize={"inherit"} />
                </Tooltip>{" "}
                : <br />
                {`Bundled Brands: ${
                  brandsData?.brands?.edges.length
                    ? brandsData?.brands?.edges.map(e => e.node.name)
                    : "None"
                }`}
                <br />
                {`Ringfence by agreement commission type: ${
                  monthlyPaymentSettingsData.ringfenceCommissionType
                    ? "On"
                    : "Off"
                }`}
              </span>
            </DialogContentText>
          </ConfirmationAlert>
        )}
        {closeMonthPushed && (
          <ConfirmationAlert
            content={dialogContent}
            onPositive={async () => {
              const res = await closeMonth();
              const status = res.data.closeMonthlyPayments.data?.status;
              if (status) {
                if (status) {
                  setGlobalState(
                    updateGlobal(globalState, monthState, {
                      status
                    })
                  );
                }
              }
              setCloseMonth(false);
            }}
            onNegative={() => {
              setCloseMonth(false);
            }}
            open={true}
            positiveAction={"Ok"}
            title={"Approve Month Closing"}
          />
        )}
        {
          <MonthlyPaymentSettingsForm
            date={monthState}
            onClose={handleCloseSettings}
            open={settingsPushed}
            settings={monthlyPaymentSettingsData}
          />
        }
        {statusError && (
          <ConfirmationAlert
            content={dialogContent}
            onPositive={async () => {
              setStatusError(null);
            }}
            open={true}
            positiveAction={"Ok"}
            title={"Action Error"}
          >
            <DialogContentText
              className={classes.whiteSpace}
            >{`${statusError.message}`}</DialogContentText>
          </ConfirmationAlert>
        )}
        {manageMonthlyPayments && (<ToggleButtonGroup
          value={statusFilter}
          exclusive
          onChange={handleStateChange}
          aria-label="text alignment"
        >
          <ToggleButton value={""} aria-label="left aligned">
            {"ALL PAYMENTS"}
          </ToggleButton>
          <ToggleButton
            value={MonthlyPaymentStatus.PENDING}
            aria-label="left aligned"
          >
            {"PENDING"}
          </ToggleButton>
          <ToggleButton value={MonthlyPaymentStatus.PAID} aria-label="centered">
            {"PAID"}
          </ToggleButton>
        </ToggleButtonGroup>)}
        <MonthlyPaymentsList
          monthClosed={
            globalState.get(monthState)?.status === MonthStatusEnum.CLOSED
          }
          monthLocked={
            globalState.get(monthState)?.status === MonthStatusEnum.LOCKED
          }
          refetch={refetch}
          monthlyPayments={monthlyPaymentsData.edges.map(edge => edge.node)}
          hasNextPage={monthlyPaymentsData.pageInfo.hasNextPage}
          hasPreviousPage={monthlyPaymentsData.pageInfo.hasPreviousPage}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          statusFilter={statusFilter}
        />
        <div hidden={!manageMonthlyPayments}>
          <LoadingButton
            color="primary"
            variant={"contained"}
            loading={!!globalState?.get(monthState)?.isExportDone}
            onClick={() => {
              setGlobalState(
                updateGlobal(globalState, monthState, { isExportDone: true })
              );
              csvExport();
            }}
          >
            Export
          </LoadingButton>
          <CSVLink
            ref={csvLink}
            filename={`payments-${monthState}.csv`}
            className="hidden"
            data={
              (exportData?.monthlyPaymentSummaries?.edges.map(edge => ({
                partnerDisplayId: edge.node.partnerDisplayId,
                partnerName: edge.node.partnerName,
                // tslint:disable-next-line:object-literal-sort-keys
                month: edge.node.month,
                cpaTotal: edge.node.cpaTotal,
                referralCost: edge.node.referralCost,
                adjustmentsTotal: edge.node.adjustmentsTotal,
                revshareTotal: edge.node.revshareTotal,
                rolloverTotal: edge.node.rolloverTotal,
                total:
                  edge.node.cpaTotal +
                  edge.node.adjustmentsTotal +
                  edge.node.revshareTotal +
                  edge.node.rolloverTotal,
                paymentDetails: edge.node.paymentDetails
              })) || []) as any
            }
            target="_blank"
          />
        </div>
      </TabPanel>
      {/*<TabPanel value={tabValue} index={2} />*/}
      {/*<TabPanel value={tabValue} index={3} />*/}
      {/*<TabPanel value={tabValue} index={4} />*/}
    </Paper>
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  block: {
    width: "50px"
  },
  errorBar: {
    backgroundColor: palette.error.main,
    height: spacing()
  },
  font: {
    fontSize: 16
  },
  formControl: { paddingBottom: spacing() },
  formInput: {
    display: "flex",
    marginRight: "auto",
    width: 400
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: spacing(2, 3)
  },
  icon: {
    marginTop: "-12px"
  },
  left: {
    flex: 1,
    paddingBottom: spacing(),
    width: "100px"
  },
  paper: {
    padding: spacing(3),
    paddingBottom: 0
  },
  spinner: {
    marginLeft: "20px",
    marginTop: "8px"
  },
  whiteSpace: {
    whiteSpace: "pre-wrap"
  }
}));

export default MonthlyPaymentsListContainer;
